#root{
  font-family: 'Montserrat', sans-serif!important;
}

.dashboard .table td{
    border-top: none;
    vertical-align: middle;
    font-weight: normal;
    color: #0F3F62;
}

.dashboard .table tr{
    background-color: #ffffff;
    border-bottom: 1px solid #D6DCE0;
    line-height: 3;
    color: #525c6e;
}

.dashboard tr td:first-child {
    padding-left: 2vw;
}


::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #f7fafc;
}
::-webkit-scrollbar {
	width: 12px;
	background-color: #f7fafc;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #0f3f62;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 800px;   
    }
}

.z-depth-0dot5{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 6px 0 rgba(0, 0, 0, 0.12) !important;
}